@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src:
      local('Roboto Light'),
      local('Roboto-Light'),
      url('/fonts/Roboto/roboto-light.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Roboto/roboto-light.woff') format('woff'); /* Modern Browsers */
  }
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      local('Roboto'),
      local('Roboto-Regular'),
      url('/fonts/Roboto/roboto-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Roboto/roboto-regular.woff') format('woff'); /* Modern Browsers */
  }
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src:
      local('Roboto Medium'),
      local('Roboto-Medium'),
      url('/fonts/Roboto/roboto-medium.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Roboto/roboto-medium.woff') format('woff'); /* Modern Browsers */
  }
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
      local('Roboto Bold'),
      local('Roboto-Bold'),
      url('/fonts/Roboto/roboto-bold.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Roboto/roboto-bold.woff') format('woff'); /* Modern Browsers */
  }
  

