.message-container {
  display: flex;
  width: 75%;
  margin: 1.5em;
  flex-direction: column;
}
 
.message-header {
    padding: 1em 2em;
    display: flex;
    width: calc(100% - 1em);
    color: #e7004e;
    font-weight: 600;
}

.message-header p {
  margin: 0;
}

.message-body {
  padding: 28px 24px;
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
}
 
.message-body p {
  margin: 0;
} 

.message-button {
  border-radius: 16px !important;
  padding: .3em 3em !important;  
}

.message-button:disabled {
  opacity: 0.4;
}

.message-full-color-button {
  background-color: #e7004e !important;
  color: white !important;
}

.message-empty-color-button {
  background-color: white !important;
  color: #e7004e !important;
}