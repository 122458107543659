.rl-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: none !important;
    outline: none;;
}

.rl-modal-header {
    height: 40px;
    width: calc(100% - 24px);
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 16px;
    letter-spacing:  2px;
    font-size: 14px;
}

.rl-modal-body {
        padding: 24px;
        font-size: 16px;
}

.rl-modal-footer {
    margin: 0 24px;
    padding:12px 0 ;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #707070;
}