.admin-heade-titleContainer {
    width: 233px;
    padding: 0 16px;
    display: flex;
}

.admin-heade-title {
    font-size: 28px;
    font-weight: normal;
    margin: auto 0px;
}



button.admin-header-btn {
    min-width: 160px;
    height: 48px;
    font-weight: 500;
    opacity: 70%;
    border-radius: 0;
}
button.admin-header-btn-active {
    opacity: 100%;
    border-bottom: 3px solid #e7004e !important;
}
button.admin-header-btn:hover {
    background-color: transparent !important;
}

//  {
//     position: absolute;
//     width: 160px;
//     height: 3px;
//     background-color: #e7004e;
//     transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// }