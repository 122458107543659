/* text */
.ellipsis {
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
}
.ellipsisTwoLines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


/* flex-box*/
.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}