.rl-red-button{
    background-color: #E7004E !important;
    border: 1px solid #e7004e !important;
    border-radius: 100px !important;
    cursor: pointer;
    color: #FFFFFF !important;
    //height: 24px;
    width: 100px;
    max-width: 150px !important;
    padding: 6px 20px ;
    font-size: 10px !important;
    font-weight: 700 !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase;
    margin: 0 4px;
    font-family: Roboto !important;
}

.rl-red-border-bottom {
    background-color: transparent !important;
    border: 1px solid #E7004E !important;
    border-radius: 15px !important;
    cursor: pointer !important;
    color: #E7004E !important;
    height: 24px;
    width: 100px;
    max-width: 150px !important;
    padding: 2px 3px !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    font-family: Roboto !important;
}

.rl-transparent-button{
    background-color: transparent !important;
    border: 1px solid transparent !important;
    cursor: pointer;
    color: #e7004e !important;
    width: 100px;
    padding: 6px 20px ;
    font-size: 10px !important;
    max-width: 150px !important;
    font-weight: 700 !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase;
    border-radius: 100px !important;
    margin: 0 4px;
    font-family: Roboto !important;
}


.rl-pinkred-button {
    background-color: #e7004e !important;
    border: 1px solid transparent !important;
    cursor: pointer;
    color: #fff !important;
    // width: 100px;
    padding: 6px 20px ;
    font-size: 10px !important;
    max-width: 150px !important;
    font-weight: 700 !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase;
    border-radius: 100px !important;
    margin: 0 4px;
    font-family: Roboto !important;
}

.rl-grey-btn {
    background-color: #E5E5E5 ;
    border: 1px solid transparent;
    cursor: pointer;
    color:  #FFFFFF;
    width: 100px;
    padding: 6px 20px ;
    font-size: 10px ;
    max-width: 150px ;
    font-weight: 700 ;
    letter-spacing: 0.2em ;
    text-transform: uppercase;
    border-radius: 100px ;
    margin: 0 4px;
    font-family: Roboto !important;
}

.rl-disable-btn {
    cursor: auto ;
    pointer-events: none; 
}