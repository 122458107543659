.user-statusContainer {
    min-height: 20px;
    width: 170px;
    font-size: 12px;
    border: 1px solid;
    border-radius: 20px;
    background: rgba(255, 255, 255, .8);
    display: flex;
    align-items: center;
}
.user-statusContainer span {
    width: calc(100% - 20px);
    padding: 0px;
    margin-bottom: 2px;
    line-height: 1;
    text-align: center;
}
.user-dotStatus {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: none;
    position: relative;
    margin-bottom: 1px;
    margin-left: 2px;
}




.user-status-orange {
    border-color:rgb(235,163,20);
    color: rgb(235,163,20);
}
.user-status-dot-orange {
    background-color: rgb(235,163,20);
}

/******************************************************/

.user-status-green {
    border-color: rgb(152,212,148);;
    color: rgb(152,212,148);;
}
.user-status-dot-green {
    background-color: rgb(152,212,148);
}

/******************************************************/

.user-status-yellow {
    border-color: rgb(246,212,20);
    color: rgb(246,212,20);
}
.user-status-dot-yellow {
    background-color: rgb(246,212,20);
}

/******************************************************/

.user-status-gray {
    border-color: rgb(122,122,122);
    color: rgb(122,122,122);
}
.user-status-dot-gray {
    background-color: rgb(122,122,122);
}

/******************************************************/

.user-status-blue {
    border-color: rgb(35,101,147);
    color: rgb(35,101,147);
}
.user-status-dot-blue {
    background-color: rgb(35,101,147);
}

/******************************************************/

.user-status-dark {
    border-color: rgb(57,57,58);
    color: rgb(57,57,58);
}
.user-status-dot-dark {
    background-color: rgb(57,57,58);
}

/******************************************************/

.user-status-violet {
    border-color: rgb(156,146,181);
    color: rgb(156,146,181);
}
.user-status-dot-violet {
    background-color: rgb(156,146,181);
}

/******************************************************/

.user-status-red {
    border-color: rgb(214,65,97);
    color: rgb(214,65,97);
}
.user-status-dot-red {
    background-color: rgb(214,65,97);
}

