.admin-status {
    height: 20px;
    width: 150px;
    font-size: 12px;
    border: 1px solid;
    border-radius: 10px;
    background: rgba(255, 255, 255, .8);
    display: flex;
    align-items: center;
}
.admin-status p {
    width: calc(100% - 20px);
    padding: 0px;
    line-height: 1;
    text-align: center;
}
.admin-status-dot {
    width: 16px;
    height: 16px;
    border: none;
    position: relative;
    margin-bottom: 1px;
    margin-left: 2px;
    border-radius: 50%;
}


/******************************************************/

.admin-status-orange {
    color: rgb(235,163,20);
}
.admin-status-dot-orange {
    background: rgb(235,163,20);
}

/******************************************************/

.admin-status-green {
    color: rgb(152,212,148);
}
.admin-status-dot-green {
    background-color: rgb(152,212,148);
}

/******************************************************/

.admin-status-yellow {
    color: rgb(246,212,20);
}
.admin-status-dot-yellow {
    background-color: rgb(246,212,20);
}

/******************************************************/

.admin-status-gray {
    color: rgb(122,122,122);
}
.admin-status-dot-gray {
    background-color: rgb(122,122,122);
}

/******************************************************/

.admin-status-blue {
    color: rgb(35,101,147);
}
.admin-status-dot-blue {
    background-color: rgb(35,101,147);
}

/******************************************************/

.admin-status-dark {
    color: rgb(57,57,58);
}
.admin-status-dot-dark {
    background-color: rgb(57,57,58);
}

/******************************************************/

.admin-status-violet {
    color: rgb(156,146,181);
}
.admin-status-dot-violet {
    background-color: rgb(156,146,181);
}

/******************************************************/

.admin-status-red {
    color: rgb(214,65,97);
}
.admin-status-dot-red {
    background-color: rgb(214,65,97);
}