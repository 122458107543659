.admin-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: none !important;
    outline: none;
    font-family: Roboto;
}
// header
.admin-modal-header {
    height: 40px;
    width: calc(100% - 22px) ;
    padding: 0 12px;
    background: #E7004E;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 16px;
    letter-spacing:  2px;
    font-size: 14px;
}
.admin-modal-headerClose {
    color: #FFFFFF;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.admin-modal-body {
    font-size: 16px;
    padding: 24px;
}

.admin-modal-footer {
    margin: 0 24px;
    padding:12px 0 ;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.87);
}
.admin-modal-btnApply {
    cursor: pointer;
    color: #E7004E;
    margin-left: 24px;
}

.admin-modal-btnCancel {
    cursor: pointer;
}