div .rl-button {
    min-width: 160px;
    color: #707070;
    margin-left: 12px;
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
}

div .rl-button-disabled {
    cursor: auto ;
    opacity: 50%;
    pointer-events: none; 
}

div .rl-button:hover {
    background-color: rgba(255, 255, 255, .3);;
}

div .rl-button-boxFilters {
    padding: 0 32px;
    height: 50px;
    border-radius: 0;
    color: #707070;
}
div .rl-button-boxFilters-active {
    border-bottom: 2px solid #707070;
}

div .rl-button-boxFilters:hover {
    background-color: transparent;
}

.rl-btnPrimary {
    padding: 5px 8px;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 100%;
    height: 28px;
    text-transform: uppercase;
    font-size: 14px;
    color: #707070;
    display: block;
    background: transparent;
    cursor: pointer;
}
.btnPrimary:hover {
    background: 'rgba(255, 255, 255, .2)',
}

