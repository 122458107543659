.disabled {
    cursor: auto ;
    opacity: 50%;
    pointer-events: none; 
}
 
/* INPUT type='number' remove arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* INPUT type='number' remove arrows Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* MODAL */
.modal-dialog .modal-content { 
  border: none;
}