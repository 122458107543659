body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroll-bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
width: 0px;
height: 0px;
}
::-webkit-scrollbar-thumb {
background: #dadee1;
border: 0px none #ffffff;
border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}
::-webkit-scrollbar-thumb:active {
  background: #a6a6a6;
}
::-webkit-scrollbar-track {
background: #ffffff;
border: 0px none #ffffff;
border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
background: #ffffff;
}
::-webkit-scrollbar-track:active {
background: #ffffff;
}
::-webkit-scrollbar-corner {
background: transparent;
}