.admin-select {
    width: 190px;
    height: 18px;
    background-color: transparent;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: none;
}

.admin-select option{
    padding: 12px;
    border: none;
    line-height: 40px;
}
.admin-select option:hover {
    background-color: #e4e4e4;
}

.admin-select option:nth-child(2n) {
    background-color: #f5f5f5;
    padding:5px 0;
}

.admin-selectContainer {
    display: inline-block;
    width: 100%;
}

.admin-fake-select {
    height: 24px;
    width: calc(100% - 12px);
    font-size: 16px;
    color: #A4A4A4;
    padding: 4px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    bottom: 2px;
    cursor: pointer;
}

.admin-fake-select-optionContainer {
    width: 100%;
    background-color: #FFFFFF;
    color: #A4A4A4;
    font-size: 11px;
    border: none;
    box-shadow: 3px 3px 6px rgba(0 0 0 / 0.16);
    position: relative;
    // left: 0px;
    // top: 0;
    z-index: 10;
    max-height: 156px;
    overflow-y: auto;
    cursor: pointer;
    // max-height: 156px ;
}
.admin-fake-select-optionContainer div {
    height: 26px;
    padding: 6px 6px; 
    display: flex;
    align-items: center;
} 

.admin-fake-select-optionContainer div:nth-child(2n) {
    background-color: #f5f5f5;
}
.admin-fake-select-optionContainer div:hover {
    background: rgb(214, 214, 214);
} 
.admin-select-imgContainer {
    position: relative;
    float: right;
}
.admin-select-deleteSelection {
    margin-right: 2px;
    height: 14px;
}

.admin-selectArrow {
    height: 10px;
}
.admin-selectArrow-open {
    transform: rotate(180deg),
}